/* eslint-disable import/prefer-default-export */
import React from "react";
import { fade, withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";

import useStyles from './style';


const BootstrapInput = withStyles((theme: any) => ({
    root: {
      "label + &": {
        // marginTop: theme.spacing(1)
        marginTop: '10px'
        // color: '#245978'
      }
    },
    input: {
      borderRadius: 7,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #ced4da",
      fontSize: 14,
      width: "100%",
      // height: '2.5em',
      fontWeight: 500,
      color: '#2F2824',
      padding: "12px",
      "&::placeholder": {
        color: "#585454"
      },
      transition: theme.transitions.create(["border"]),
      "&:focus": {
        border: "1px solid #D8D8D8",
        transition: theme.transitions.create(["border"]),
      },
      "&:hover": {
        border: "1px solid #363840",
        transition: theme.transitions.create(["border"]),
      }
    }
  }))(InputBase);

export const LabledInput = ({
    inputRef,
    errors,
    autoFocus,
    placeholder,
    required=false,
    hasIcon = false,
    IconURL,
    className,
    value,
    id,
    label
}: any) => {
    const classes = useStyles();

    return (
      <>
        <InputLabel
          className={classes.inputLabel}
          shrink
          htmlFor="bootstrap-input"
        >
          {label} {required ? <div className={classes.asterik}>*</div> : null}
        </InputLabel>
        {hasIcon ?
          <div className={classes.inputContainer}>
            <div className={classes.iconContainer}>
              <img
                src={IconURL}
                alt="icon"
                className={classes.iconItem}
              />
            </div>
            <BootstrapInput
              id={id}
              name={id}
              placeholder={placeholder}
              required={required}
              inputRef={inputRef}
              fullWidth
              className={`${className} ${classes.iconedInput}`}
              defaultValue={value}
            />
          </div>:
          <BootstrapInput
            id={id}
            name={id}
            placeholder={placeholder}
            required={required}
            inputRef={inputRef}
            fullWidth
            className={className}
            defaultValue={value}
          />
        }
      </>
    );
}
