import React, { FC, useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import moment from "moment";
import { RouteComponentProps } from "@reach/router";
import useAppContext from "../../context/store/store";
import {
  IRecruiter,
  ECreationType,
  IAddRecruiterInTouch,
} from "compass-shared";
import { IRecruiterProfile } from "./interface";
import CreatedCandidatesCount from "../common/CreatedCandidatesCount";
import { clientsRef, clientsJobsRef, clientsCompanyRef } from "../../FirebaseUtils/collectionRefs";
import useStyles from "./style";

const initialState = {
  jobs_created: [],
  company: {
    name: ""
  }
};

const ClientProfile: FC<RouteComponentProps & IRecruiterProfile> = ({ id }) => {
  const [store]: any = useAppContext();
  const [recruiter, setRecruiter]: any = useState<IRecruiter>({} as IRecruiter);
  const [state, setState] = useState(initialState);
  const classes = useStyles();

  const getJobsCreated = React.useCallback(async () => {
    const clientJobsSnap = await clientsJobsRef
      .where("created_by", "==", id)
      .get();
    const list: any[] = [];
    if (!clientJobsSnap.empty) {
      clientJobsSnap.forEach((docs: any) => {
        const data = docs.data();
        list.push({ ...data, id: docs.id });
      });
    }
    setState((st: any) => ({
      ...st,
      jobs_created: list,
    }));
  }, [id]);
  
  useEffect(() => {
    const profileDetails = id ? store.clients[id] : {};
    setRecruiter(profileDetails);

    if (id) {
      getJobsCreated();
    }
  }, [id]);

  const companyDetails = React.useCallback(async () => {
    if(id && !(store?.clients[id] && store?.clients[id].company_id)) {
      const companyID = store.clients[id].company_id;
      const compRef = await clientsCompanyRef.doc(companyID).get();
      if(compRef.exists) {
        setState((st:any) => ({
          ...st,
          company: compRef.data()
        }))
      }
    }
  }, [id]);

  useEffect(() => {
    companyDetails();
  }, [companyDetails]);

  const showJobLogs = () => {
    if (state.jobs_created?.length) {
      return state.jobs_created.map((el: any) => (
        <Typography color="textSecondary">
          {/* {moment(el?.created_at?.toDate()).format("DD MMM YYYY, h:mm:ss a")} - {el.id} */}
          {moment(el?.created_at?.toDate()).format("DD MMM YYYY")} - {el.id}
          <br></br>
        </Typography>
      ));
    } else {
      return null;
    }
  };

  return id ? (
    <Card className={classes.recruiterProfileCard}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {`${recruiter?.firstName} ${recruiter?.lastName}`}
        </Typography>
        {recruiter?.company_name && (
            <Typography variant="h6" color="secondary" component="h3">
              {recruiter?.company_name || state.company?.name}
            </Typography>
          )}
        <Typography variant="body2" component="p">
          {recruiter?.bio}
        </Typography>
        <br />
        <Typography
          className={classes.contactTexts}
          variant="body2"
          component="p"
        >
          Jobs created [{state.jobs_created.length || 0}]
        </Typography>
        {showJobLogs()}
        <Box display="flex" style={{ marginTop: 20 }} alignItems="center">
          <Typography
            style={{ marginRight: 10 }}
            className={classes.contactTexts}
            variant="body2"
            component="p"
          >
            ClientID:
          </Typography>
          <Typography color="textSecondary">
            {id || "did not get string"}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            style={{ marginRight: 10 }}
            className={classes.contactTexts}
            variant="body2"
            component="p"
          >
            Created at:
          </Typography>
          <Typography color="textSecondary">
            {moment(recruiter?.created_at?.toDate()).format(
              "DD MMM YYYY, h:mm:ss a"
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  ) : null;
};

export default React.memo(ClientProfile);
