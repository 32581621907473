import React, { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import { RouteComponentProps } from "@reach/router";
import LinkedInCookie from "../../../Components/SettingItems/LinkedInCookie";
import { adminSettingRef } from "../../../FirebaseUtils/collectionRefs";
import { CompassButton, ErrorMsg } from "compass-shared";
import useStyles from "./style";

import { CSVLink } from "react-csv";
import { Box, Grid } from "@material-ui/core";
import { candidatesRef } from "../../../FirebaseUtils/collectionRefs";
import MetricCard from "../../../Components/Dashboard/MetricCard";

const initialState = {
    'total-candidates': {total : 0},
    activeJobOpening: 0,
    allJobOpening: 0,
    jobFunctionList: { headers: [], data: [] },
    isExportinSkills: '',
}

const SettingPage: FC<RouteComponentProps> = () => {
    const [setting, setSetting]: any = useState({});
    const [loading, setLoading]: any = useState(false);
    const [updatedSetting, setUpdatedSetting]: any = useState({});
    const [state, setState] = useState(initialState);


      const exportSkills = async() => {
        setState(st => ({ ...st, isExportinSkills: 'pending' }))
        const candidateSnap = await candidatesRef.get();
        if(!candidateSnap.empty) {
            let jobFunctionList: any[] = [];
            candidateSnap.docs.map(async(el: any) => {
                const data = el.data();
                let list: any[] = jobFunctionList[data.job_function] && jobFunctionList[data.job_function].skills || [];
                if(data.skills) {
                    list.push(...data.skills);
                }
                jobFunctionList[data.job_function] = {
                    job_function: data.job_function,
                    skills: list
                }
            });
            const dataList: any[] = [];
            Object.keys(jobFunctionList).map((i:any) => {
                if(jobFunctionList[i].skills.length) {
                    jobFunctionList[i].skills.map((el: any) => {
                        dataList.push({
                            job_function: i,
                            skill: el.name
                        });
                    })
                }
            })

            setState((st: any) => ({
                ...st,
                isExportinSkills: 'fulfilled',
                jobFunctionList: {
                    headers: [
                        {label: 'Job Function', key: 'job_function'},
                        {label: 'Recommended Skill', key: 'skill'},
                    ],
                    data: dataList
                }
            }));
        }
    }

    const getSetting = async () => {
        setLoading(true);
        try {
            const data = (await adminSettingRef.doc("admin").get()).data();
            setSetting(data);
        } catch (error) {
            toast.error(<ErrorMsg text="Something went wrong when getting setting." />);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSetting();
    }, []);

    const saveSetting = async () => {
        setLoading(true);
        try {
            await adminSettingRef.doc("admin").update(updatedSetting);
        } catch (error) {
            toast.error(<ErrorMsg text={error.message || "Something went wrong."} />);
        } finally {
            setLoading(false);
        }
    };

    const classes = useStyles();
    return (
        <Box>
        <div className={classes.paperWrapper}>
            <Paper>
                <div style={{ fontSize: 23 }}>Settings</div>
                <br />
                <LinkedInCookie
                  cookie={setting?.linkedin_cookie || ""}
                  onChange={(sessionCookie: string) => {
                      if (!sessionCookie) return;
                      setUpdatedSetting((state: any) => ({
                          ...state,
                          linkedin_cookie: sessionCookie
                      }));
                  }}
                />
                <br />
                <br />
                <div style={{ width: 100 }}>
                    <CompassButton
                      fullWidth
                      size="medium"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    //   className={classes.button}
                      onClick={saveSetting}
                    >
                    Save
                    </CompassButton>
                </div>
            </Paper>
        </div>
                     <Grid container spacing={3} style={{margin: 8}}>
                        <Grid onClick={exportSkills} item style={{ padding: 0, cursor: 'pointer' }}>
                            <MetricCard
                                title={(
                                    <div>
                                        <p>
                                            {state.isExportinSkills === 'pending' ? 'Exporting ...' :
                                                state.isExportinSkills === 'fulfilled' ? 'Export again' : 'Export skills'
                                            }
                                        </p>
                                    </div>
                                )}
                                loading={state.isExportinSkills === 'pending'}
                            />
                        </Grid>
                        <Grid item style={{ padding: 0, margin: 12, cursor: 'pointer' }}>
                            {state.isExportinSkills === 'fulfilled' ?
                                <div style={{ height: '100%', alignItems: 'flex-end', display: 'flex'}}>
                                    <CSVLink data={state.jobFunctionList.data} headers={state.jobFunctionList.headers}>Download csv</CSVLink>
                                </div>: null
                            }
                        </Grid>
                </Grid>
        </Box>
    );
};

export default SettingPage;
