import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    sidebarContainer: {
        overflow: "hidden",
        width: "240px",
        backgroundColor: "#165A88",
        height: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    sidebar: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
    },
    sidebarHeader: {
        backgroundColor: "#ffffff16",
        color: "#ffffff",
        padding: "5px 0 5px 1em"
    },
    logo: {
        height: theme.spacing(6),
    },
    sidebarList: {
        backgroundColor: "#1A6CA3",
        color: "#ffffff",
        marginBottom: 10
    },
    sidebarContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginTop: "16px"
    },
    profileAvatar: {
        marginRight: "0.8em",
        height: theme.spacing(7),
        width: theme.spacing(7),
    },
    listItem: {
        borderRadius: theme.spacing(0.5),
        height: "2.5rem",
    },
    focus: {
        backgroundColor: "#9FA2B4 !important"
    },
    button: {
        width: "100%",
        marginTop: "1em",
        borderRadius: "30px",
        padding: 0
    },
    wrap: { overflowWrap: "break-word" },
    text: {
        color: "#ffffff",
        textTransform: "uppercase",
        textDecoration: "none",
        fontSize: "12px",
        outline: "none"
    },
    rootText: {
        fontSize: "12px !important",
        color: theme.palette.primary.main
    },
    icon: {
        height: theme.spacing(7),
        width: theme.spacing(7),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    iconSize: {
        height: "1em",
        width: "1em"
    },
    listWrapper: { margin: "1em", width: "85%" },
    active: {
        backgroundColor: "#9FA2B4",
    },
    activeCandidateIcon: {
        color: "#FF9DCC"
    },
    hr: {
        height: "3px",
        backgroundColor: "#70707029"
    },
    gear: {
        color: "#cbccf8"
    },
    sidebarFooter: {
        backgroundColor: "#ffffff16",
        color: "#ffffff",
        height: theme.spacing(6),
        display: "flex",
        alignItems: "center",
        paddingLeft: "2em",
        cursor: "pointer",
        textTransform: "uppercase",
        fontWeight: "bold",
        border: "none",
        outline: "none"
    },
    signOutButton: {
        justifyContent: "unset"
    },
    childrenContainer: {
        padding: "1em"
    }
}));

export default useStyles;
