import { firestore } from "../firebase";

export const recruitersRef: firebase.firestore.CollectionReference = (
    firestore.collection("recruiters")
)
;
export const clientsRef: firebase.firestore.CollectionReference = (
    firestore.collection("clients_users")
);

export const clientsJobsRef: firebase.firestore.CollectionReference = (
    firestore.collection("clients_jobs")
);

export const clientsCompanyRef: firebase.firestore.CollectionReference = (
    firestore.collection("clients_company")
);

export const candidatesRef: firebase.firestore.CollectionReference = (
    firestore.collection("candidates")
);

export const recruiterLogsRef: firebase.firestore.CollectionReference = (
    firestore.collection("recruiter_logs")
);

export const adminSettingRef: firebase.firestore.CollectionReference = (
    firestore.collection("admin_settings")
);

export const tallyUserRef: firebase.firestore.CollectionReference = (
    firestore.collection('tally_users')
);

export const subscribedQueriesRef: firebase.firestore.CollectionReference = (
    firestore.collection("subscribed_queries")
);

export const candidatesAnalyticsRef: firebase.firestore.CollectionReference = (
    firestore.collection("candidates_profile_analytics")
);
