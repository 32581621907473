import React, {
    FC, MouseEventHandler, useState
} from "react";
import { toast } from "react-toastify";
import { Link, RouteComponentProps } from "@reach/router";
import { useForm } from "react-hook-form";
import {
    Container,
    Grid,
    Typography
} from "@material-ui/core";
import {
    signInWithEmailAndPassword,
    signInWithGMail
} from "../../../Components/AuthenticationProvider/AuthenticationProvider";
import {
    GoogleButton, SubmitButton, PasswordField, EmailField
} from "compass-shared";
import { SignInSchema } from "../../../Utils/FormValidationSchema";
import useStyles from "./styles";
import {
    ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD
} from "../../../routes";
import { ErrorMsg } from "compass-shared";
import CompassLogo from "../../../Assets/img/compassLogo.png";

const SignIn: FC<RouteComponentProps> = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: SignInSchema
    });
    const classes = useStyles();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            await signInWithEmailAndPassword(data.email, data.password);
            setIsLoading(false);
        } catch (error) {
            // eslint-disable-next-line max-len
            if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
                // eslint-disable-next-line max-len
                toast.error(<ErrorMsg text="Invalid credentials. Please try again" />);
            } else {
                toast.error(<ErrorMsg text={error.message} />);
            }
            setIsLoading(false);
        }
    };

    const handleClick: MouseEventHandler = async () => {
        setIsLoading(true);
        await signInWithGMail();
        setIsLoading(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <img
                  src={CompassLogo}
                  alt="compass logo"
                  className={classes.compassLogo}
                />
                <br />
                <br />
                <Typography component="h1" variant="h5">
                    Admin sign in
                </Typography>
                <br />
                <GoogleButton
                  handleClick={handleClick}
                  text="Google"
                  loading={isLoading}
                />
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={classes.form}
                >
                    <EmailField register={register} errors={errors} autoFocus />
                    <PasswordField register={register} errors={errors} />
                    <SubmitButton
                      className={classes.submit}
                      text="Sign In"
                      loading={isLoading}
                    />
                    <Grid container>
                        <Grid item xs>
                            <Link to={ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD}>
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default SignIn;
