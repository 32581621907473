import React, { FC } from "react";
import {
    Card, CardContent, CircularProgress, Typography
} from "@material-ui/core";
import useStyles from "./style";
import { IMetricCard } from "./interface";

const MetricCard: FC<IMetricCard> = ({ metric, title, loading }) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent classes={{ root: classes.root }} style={{ textAlign: "center" }}>
                <Typography variant="h3" component="h2" style={{ paddingBottom: "12px" }}>
                    { loading ? <CircularProgress /> : metric}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ textTransform: "uppercase" }}
                  className={classes.cardLabel}
                >
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default MetricCard;
