import { IAdmin } from "compass-shared";
import { ActionType, IAction, IRecruiterMap, IResetTypeData } from "./Interface";
import { IApprovalData } from "../../Components/Recruiters/interface";

// Action creators can be created here
// All action creators return an IAction
// eslint-disable-next-line import/prefer-default-export
export const doSetUser = (payload: IAdmin | {}): IAction => ({
    type: ActionType.SET_USER, payload
});

export const doSetRecruiters = (payload: IRecruiterMap | {}): IAction => ({
    type: ActionType.SET_RECRUITERS, payload
});

export const doSetClients = (payload: IRecruiterMap | {}): IAction => ({
    type: ActionType.SET_CLIENTS, payload
});

export const doApproveRecruiter = (payload: IApprovalData): IAction => ({
    type: ActionType.APPROVE_RECRUITER, payload
});

export const doResetRecruiterType = (payload: IResetTypeData): IAction => ({
    type: ActionType.RESET_RECRUITER_TYPE, payload
});

export const doApproveClient = (payload: IApprovalData): IAction => ({
    type: ActionType.APPROVE_CLIENT, payload
});

