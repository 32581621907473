import React, { FC, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Switch,
    Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Link, RouteComponentProps } from "@reach/router";
import useStyles from "./style";
import { UserAvatar, SubmitButton } from "compass-shared";
import { IRecruiterCardProps } from "./interface";
import CreatedCandidatesCount from "../common/CreatedCandidatesCount";
import Slack from '../../Assets/icons/slack.svg';
import { InputValidationSchema } from '../../Utils/FormValidationSchema';
import { LabledInput } from './component/LabeledInput';
import { recruitersRef } from "../../FirebaseUtils/collectionRefs";

const RecruiterCard: FC<RouteComponentProps & IRecruiterCardProps> = ({
    src, name, email, bio, isActive, id, handleOpen, candidateFromExtensionCount, candidateFromWebsiteCount,
    slack_id
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [slackId, setSlackId] = useState('');
    const {
        register, errors, handleSubmit
    } = useForm({
        validationSchema: InputValidationSchema,
        // defaultValues: slack_id,
        mode: "onChange"
    });
    const onFormSubmit = (data: any) => {
        const {
            slack_id
        } = data;

        setLoading(true);
        recruitersRef
        .doc(id)
        .set({ slack_id }, {merge: true})
        .then(res => {
            setLoading(false);
            setSlackId(slack_id);
        })
        .catch(() => {
            setLoading(false);
        })
    };

    return (
        <Grid item className={classes.root}>
            <Card style={{ display: "flex" }} className={classes.responsiveCard}>
                <Link to={`${id}`} style={{ textDecoration: "none", color: "unset" }}>
                    <Box p={2}>
                        <UserAvatar src={src || ""} styleClass={classes.media} />
                    </Box>
                </Link>
                <div
                    style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                    <CardContent
                    className={classes.cardContent}
                    classes={{ root: classes.cardContentRoot }}
                    >
                        <Link
                        to={`${id}`}
                        style={{ textDecoration: "none", color: "unset", width: "60%" }}
                        >
                            <Box width="100%">
                                <Typography variant="h5" component="h2" style={{ textTransform: "capitalize" }}>
                                    {name}
    &nbsp;
                                    <CreatedCandidatesCount
                                    fromExtensionCount={candidateFromExtensionCount || 0}
                                    fromWebsiteCount={candidateFromWebsiteCount || 0}
                                    />
                                </Typography>
                                <Typography
                                variant="subtitle2"
                                component="p"
                                color="secondary"
                                style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
                                >
                                    {email}
                                </Typography>
                                <Typography
                                variant="body2"
                                component="p"
                                className={classes.story}
                                >
                                    {bio}
                                </Typography>
                            </Box>
                        </Link>
                        <Box pl={1} pr={1} textAlign="end" width="30%">
                            <Typography
                            className={
                                    isActive ? classes.checkedSwitch : classes.uncheckedSwitch
                                }
                            style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: 12 }}
                            >
                                {isActive ? "Approved" : "Not Approved"}
                            </Typography>
                            <Switch
                                checked={isActive}
                                edge="end"
                                color="secondary"
                                onClick={() => handleOpen(id, name, isActive)}
                                name="isActive"
                                inputProps={{ "aria-label": "primary checkbox" }}
                                classes={{
                                    colorSecondary: isActive
                                        ? classes.checkedSwitch
                                        : classes.uncheckedSwitch,
                                    track: isActive
                                        ? classes.checkedTrack
                                        : classes.uncheckedTrack,
                                }}
                            />
                        </Box>
                    </CardContent>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                        <form
                            className={classes.SlackInputForm}
                            onSubmit={handleSubmit(onFormSubmit)}
                        >
                            <div>
                                <LabledInput
                                    inputRef={register}
                                    errors="errors"
                                    id="slack_id"
                                    label="Slack Member ID"
                                    required
                                    hasIcon
                                    IconURL={Slack}
                                    value={`${(slackId || slack_id) || 'none'}`}
                                    // className={classes.textInputStyles}
                                />
                                {errors.slack_id
                                && (
                                    <Box color="error.main">
                                        {(errors.slack_id as any).message}
                                    </Box>
                                )}
                            </div>
                            <SubmitButton
                                className={classes.changeProfileAvatar}
                                loading={loading}
                                disabled={loading}
                                text={(slackId || slack_id) ? "Update Slack ID" : "Save "}
                            />
                        </form>
                    </div>
                </div>
            </Card>
        </Grid>
    );
};

export default RecruiterCard;
