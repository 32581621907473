import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: "100%",
        padding: "8px"
    },
    recruitersContainer:{
        "& > div":{
            width: "100%",
        }
    },
    cardContentRoot: {
        "&:last-child": {
            paddingBottom: theme.spacing(2)
        }
    },
    wrapper: {
        height: "90vh",
        overflow: "hidden",
        overflowY: "scroll",
    },
    childWrapper: {
        [theme.breakpoints.between(600, 850)]: {
            flexBasis: "100%"
        },
    },
    cardContent: {
        display: "flex",
        width: "100%",
        justifyContent: "space-around"
    },
    responsiveCard: {
        [theme.breakpoints.between(960, 1150)]: {
            justifyContent: "center",
            flexWrap: "wrap",
        },
        [theme.breakpoints.between(601, 780)]: {
            justifyContent: "center",
            flexWrap: "wrap",
        },
        [theme.breakpoints.between(0, 550)]: {
            justifyContent: "center",
            flexWrap: "wrap",
        },
    },
    media: {
        width: "100px",
        height: "100px",
        borderRadius: "50%"
    },
    checkedSwitch: {
        color: "#438051 !important",
    },
    checkedTrack: {
        backgroundColor: "#438051 !important",
    },
    uncheckedSwitch: {
        color: "#B73328",
    },
    uncheckedTrack: {
        backgroundColor: "#B73328",
    },
    story: {
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        paddingTop: theme.spacing(0.5),
        overflow: "hidden",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        hyphens: "auto",
        textOverflow: "ellipsis"
    },
    SlackInputForm: {
        display: 'flex',
        width: '94%',
    },
    changeProfileAvatar: {
        backgroundColor: "#FCC46B",
        borderRadius: "7px",
        fontSize: "15px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#221A16",
        fontWeight: 600,
        padding: "0 2em",
        marginRight: ".5em",
        height: "2.8em",
        width: "12rem",
        marginTop: "1.5rem",
        marginLeft: "2rem",
        boxShadow: 'none',
        "&:hover": {
            color: "#221A16",
            backgroundColor: "#f1db93"
        }
    },
    contactTexts: {
        color: "#2F2824",
        fontSize: 14,
        fontWeight: 600
    },
    directContact: {
        marginBottom: '1rem'
    },
    recruiterProfileCard: {
        overflowY: 'scroll',
        maxHeight: '90vh'
    }
}));

export default useStyles;
