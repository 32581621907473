import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
    root: {
        padding: "unset !important",
        paddingBottom: "unset !important",

        "& > *": {
            fontWeight: 500,
        },
    },

    dashboard: {
        // flexDirection: "column",
        margin: "auto",
        width: "85%",
        justifyContent: "space-around",
        minHeight: "60vh",
    },

    salutation: {
        display: "grid",
        gridTemplateColumns: "max-content 1fr",
        gridGap: "2em",
    },

    metrics: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        justifyItems: "start"
    },

    card: {
        display: "flex",
        flexDirection: "column",
        padding: "28px",
        alignItems: "center",
        justifySelf: "stretch",
        minWidth: '15em'
    },

    cardLabel: {
        "& > * > *": {
            margin: 0
        }
    },

    graph: {
        marginTop: "1em"
    }
}));

export default useStyles;
