import React, { useReducer } from "react";
import { Router } from "@reach/router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    createMuiTheme,
    CssBaseline,
    ThemeProvider
} from "@material-ui/core";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import { AppContext } from "./context/store/store";
import { appReducer, initialState } from "./context/reducer/reducer";
import { PrivateRoutes, PublicRoutes } from "./Pages";

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window { google: any }
}

window.google = window.google || {};

const theme = createMuiTheme({
    palette: {
        common: {
            black: "rgba(54, 55, 64, 1)",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fafafa"
        },
        primary: {
            light: "rgba(235, 235, 235, 1)",
            main: "rgba(54, 55, 64, 1)",
            contrastText: "#fff"
        },
        secondary: {
            main: "rgba(159, 162, 180, 1)",
            light: "#ffffff"
        },
        error: {
            main: "#e57373"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.8)",
            secondary: "rgba(54, 55, 64, 0.71)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(159, 162, 180, 1)"
        }
    }
});

const App = () => {
    const [state, dispatch] = useReducer(appReducer, initialState);

    toast.configure({
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });

    return (
        <ErrorBoundary>
            <AppContext.Provider value={[state, dispatch]}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <PublicRoutes path="/public/*" />
                        <PrivateRoutes default />
                    </Router>
                </ThemeProvider>
            </AppContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
