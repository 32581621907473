import React, { FC } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { IProp } from "./Interface";
import useStyles from "./style";

const CreatedCandidatesCount: FC<IProp> = ({ fromExtensionCount, fromWebsiteCount }) => {
    const classes = useStyles();

    return (
        <Tooltip
          title={(
              <>
                  <div>
From extension:
                      {fromExtensionCount}
                  </div>
                  <div>
From website:
                      {fromWebsiteCount}
                  </div>
              </>
            )}
          arrow
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
        >
            <span>
[
                {(fromExtensionCount || 0) + (fromWebsiteCount || 0)}
]
            </span>
        </Tooltip>
    );
};

export default React.memo(CreatedCandidatesCount);
