import React, {
    ReactNode,
    FC,
    useEffect,
    useState,
    createContext
} from "react";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { auth, firebase } from "../../firebase";

import { AuthStatus } from "./AuthenticationProviderTypes";
import useAppContext from "../../context/store/store";
import { doSetUser } from "../../context/reducer/Actions";
import { IAdmin, LoadingPage, WarnMsg } from "compass-shared";

export type AuthenticationContextAttributes = IAdmin | {};

interface IAuthenticationProviderProps {
    children: ((props: any) => ReactNode) | ReactNode;
    renderWhenUnauthenticated: () => ReactNode;
}

const provider = new firebase.auth.GoogleAuthProvider();
export const AuthenticationContext = createContext<AuthenticationContextAttributes>({});

export const signInWithToken = (token: string): void => {
    auth.signInWithCustomToken(token).catch(console.error);
};
export const signInWithEmailAndPassword = (email: string, password: string) => (
    auth.signInWithEmailAndPassword(email, password)
);

export const signInWithGMail = async () => await auth.signInWithPopup(provider);

export const sendPasswordResetEmail = async (email: string) => (
    await auth.sendPasswordResetEmail(email)
);

export const signOut = (): void => {
    auth.signOut().then(() => {
        navigate("/");
    }).catch(console.error);
};

const useAuthentication = () => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.unknown);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, dispatch]: any = useAppContext();

    const onSignOut = () => {
        setAuthStatus(AuthStatus.unauthenticated);
        dispatch(doSetUser({} as IAdmin));
        signOut();
    };

    useEffect(() => {
        const onSignIn = async (firebaseUser: any) => {
            const result = await firebaseUser.getIdTokenResult();

            if (!result.claims.isAdmin) {
                toast.warn(<WarnMsg text="You do not have access to this panel" />);
                await onSignOut();
            } else {
                const adminUserObj: IAdmin = {
                    uid: firebaseUser.uid,
                    displayName: firebaseUser.displayName,
                    email: firebaseUser.email,
                    photoURL: firebaseUser.photoURL
                };
                dispatch(doSetUser(adminUserObj));
                setAuthStatus(AuthStatus.authenticated);
            }
        };

        auth.onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
                onSignIn(firebaseUser).catch(console.error);
            } else {
                onSignOut();
            }
        });
        // eslint-disable-next-line
    }, []);
    return authStatus;
};

// eslint-disable-next-line max-len
const AuthenticationProvider: FC<IAuthenticationProviderProps> = ({
    children,
    renderWhenUnauthenticated
}) => {
    const authStatus = useAuthentication();

    return (
        <AuthenticationContext.Provider value={{}}>
            {authStatus === AuthStatus.unknown
                ? <LoadingPage text="Connect Admin Tools" size={50} />
                : authStatus === AuthStatus.authenticated
                    ? children
                    : renderWhenUnauthenticated()}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
