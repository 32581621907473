import { IRecruiter } from "compass-shared";

export interface IAction {
    type: ActionType;
    payload: any;
}
export interface IRecruiterMap {
    [key: string]: IRecruiter;
}

export interface IResetTypeData {
    uid: string;
}

export enum ActionType {
    SET_USER = "SET_USER",
    SET_RECRUITERS = "SET_RECRUITERS",
    SET_CLIENTS = "SET_CLIENTS",
    APPROVE_RECRUITER = "APPROVE_RECRUITER",
    APPROVE_CLIENT = "APPROVE_CLIENT",
    RESET_RECRUITER_TYPE = "RESET_RECRUITER_TYPE"
}
