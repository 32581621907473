import React, { FC } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import useStyles from "./style";

// eslint-disable-next-line max-len
const Header: FC<any> = (props) => {
    const { showAppBar, handleDrawerToggle, children } = props;
    const classes = useStyles();
    return showAppBar && (
        <AppBar
          position="fixed"
          className={classes.appBar}
          color="transparent"
          classes={{
              root: classes.elevation4
          }}
        >
            <Toolbar classes={{ regular: classes.regular }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                {/* TODO Add the header component here */}
                {/* TODO Make this configurable */}
                <div style={{ width: "100%" }}>
                    {children}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
