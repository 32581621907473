import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
      inputLabel: {
        color: "#2F2824",
        textTransform: "capitalize",
        display: "flex",
        fontWeight: 600,
        fontSize: "14px",
        transform: "initial",
      },
      asterik: {
        color: "red",
        paddingLeft: "2px",
      },
      inputContainer: {
        marginTop: "10px",
        position: 'relative'
      },
      iconContainer: {
        position: "absolute",
        height: "3em",
        width: "3.5em",
        zIndex: 1,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      iconItem: {},
      iconedInput: {
        "& input": {
          paddingLeft: "2.5em",
        },
      },
}));

export default useStyles;
