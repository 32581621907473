import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  capitalize,
} from "@material-ui/core";
import moment from "moment";
import { RouteComponentProps } from "@reach/router";
import useAppContext from "../../context/store/store";
import {
  IRecruiter,
  ECreationType,
  IAddRecruiterInTouch,
} from "compass-shared";
import { doResetRecruiterType } from "../../context/reducer/Actions";
import { IRecruiterProfile } from "./interface";

import CreatedCandidatesCount from "../common/CreatedCandidatesCount";
import {
  candidatesAnalyticsRef,
  candidatesRef,
  recruitersRef,
} from "../../FirebaseUtils/collectionRefs";
import useStyles from "./style";

const initialState = {
  requestedIntrosSize: 0,
  directContactsSize: 0,
  requestedIntros: [],
  directContacts: [],
};

let totalCandidatesSize = 0;
let requestedIntrosSize = 0;
let directContactsSize = 0;

const RecruiterProfile: FC<RouteComponentProps & IRecruiterProfile> = ({
  id,
}) => {
  const [store, dispatch]: any = useAppContext();
  const [recruiter, setRecruiter]: any = useState<IRecruiter>({} as IRecruiter);
  const [ownCandidates, setOwnCandidates] = useState<IAddRecruiterInTouch[]>(
    [] as IAddRecruiterInTouch[]
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [state, setState] = useState(initialState);
  const [profileAnalyticsData, setProfileAnalytics] = useState<Array<any>>([]);
  const classes = useStyles();

  const fetchOwnCandidatesData = async (candidateIds: string[]) => {
    const usersPromise: any[] = [];
    candidateIds.map((id) => {
      usersPromise.push(candidatesRef.doc(id).get());
    });

    Promise.all(usersPromise).then((docs) => {
      const data = docs.map((doc, index) => {
        const details: any = doc.data();
        if (details?.creation_type && details?.created_at) {
          return {
            candidate_id: candidateIds[index],
            creation_type: details?.creation_type || "",
            is_join: true,
            joined_at: details.created_at,
            candidateID: candidateIds[index],
            requested_at: details?.created_at,
          };
        }
      });
      setOwnCandidates(data as IAddRecruiterInTouch[]);
      setIsLoading(false);
    });
  };

  const getOwnCandidates = async () => {
    setIsLoading(true);
    setOwnCandidates([]);
    const recruiterData = await recruitersRef.doc(id).get();
    if (recruiterData.exists) {
      const data = recruiterData.data();
      if (data?.touched_candidate) {
        const keys = Object.keys(data?.touched_candidate);
        const candidateIds = keys.filter(
          (key) => data?.touched_candidate[key] !== false
        );
        fetchOwnCandidatesData(candidateIds);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleRecruiterTypeReset = () => { 
    dispatch(doResetRecruiterType({ uid: id as string }));
    dispatch(doResetRecruiterType({ uid: id as string }));
    dispatch(doResetRecruiterType({ uid: id as string }));
    recruitersRef
      .doc(id)
      .set(
        { recruiter_type: null, on_boarding_stage: "/onboarding/choose-path" },
        { merge: true }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  const fectRequestData = async () => {
    await recruitersRef
      .doc(id)
      .collection("candidates_i_requested_intro")
      .onSnapshot((snap) => {
        let list = [] as any;
        if (!snap.empty) {
          snap.forEach((el) => {
            list.push(el.data());
          });
        }
        setState((st) => ({
          ...st,
          requestedIntrosSize: snap.size,
          requestedIntros: list,
        }));
      });
    await recruitersRef
      .doc(id)
      .collection("candidates_i_direct_contact")
      .onSnapshot((snap) => {
        let list = [] as any;
        if (!snap.empty) {
          snap.forEach((el) => {
            list.push(el.data());
          });
        }
        setState((st) => ({
          ...st,
          directContactsSize: snap.size,
          directContacts: list,
        }));
      });
  };

  const fetchViewCandidateAnalytics = async () => {
    await candidatesAnalyticsRef
      .where("recruiterId", "==", id)
      .orderBy("viewed_at", "desc")
      .onSnapshot((snap) => {
        const data: Array<any> = [];
        snap.forEach((doc: any) => data.push(doc.data()));
        setProfileAnalytics(data as any);
      });
  };

  useEffect(() => {
    const profileDetails = id ? store.recruiters[id] : {};
    setRecruiter(profileDetails);

    if (id) {
      getOwnCandidates();
      fectRequestData();
      fetchViewCandidateAnalytics();
    }
  }, [id, store ]);

  let sectionedDateItems = {};
  let itemCount = 0;
  const getReqTime = (dateItem: any, elm: any) => {
    if (typeof dateItem.toDate === "function" && dateItem.toDate()) {
      const parsedDate = moment(dateItem.toDate()).format("YYYY MMMM");
      itemCount = itemCount + 1;
      if (!Object.keys(sectionedDateItems).includes(parsedDate)) {
        sectionedDateItems[parsedDate] = [
          { dateItem, candidateId: elm.candidateID },
        ];
      } else {
        sectionedDateItems[parsedDate].push({
          dateItem,
          candidateId: elm.candidateID,
        });
      }
    } else {
      Object.values(dateItem).forEach((el: any) => {
        if (typeof el.toDate === "function") {
          getReqTime(el, elm);
        }
      });
    }
    return itemCount;
  };

  const renderTitle = (itemName: string, count: number) => {
    if (itemName === "requestedIntros") {
      requestedIntrosSize = 0;
      return (
        <Typography
          className={classes.contactTexts}
          variant="body2"
          component="p"
        >
          Request Intro [{count}]
        </Typography>
      );
    } else if (itemName === "totalCandidates") {
      totalCandidatesSize = 0;
      return (
        <Typography
          className={classes.contactTexts}
          variant="body2"
          component="p"
        >
          Candidate created [{ownCandidates.length}]
        </Typography>
      );
    } else if (itemName === "directContacts") {
      directContactsSize = 0;
      return (
        <Typography
          className={classes.contactTexts}
          variant="body2"
          component="p"
        >
          Direct Contact [{count}]
        </Typography>
      );
    } else if (itemName === "candidatesOpened") {
      return (
        <Typography
          className={classes.contactTexts}
          variant="body2"
          component="p"
        >
          Candidates Opened [{count}]
        </Typography>
      );
    } else {
      return null;
    }
  };

  const sectionedDates = (arr: any, itemName: string) => {
    if (arr.length) {
      let c = 0;
      sectionedDateItems = {};
      arr.forEach((el: any, i: number) => {
        if (el && el.requested_at) {
          c = getReqTime(el.requested_at, el);
        }
      });
      itemCount = 0;
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const availableKeys = [] as string[];
      const obj = {};
      Object.keys(sectionedDateItems).map((ell: any) => {
        obj[ell.split(" ")[1]] = ell.split(" ")[0];
      });
      const sortedSectionedDateItems = Object.keys(obj).sort(
        (a: any, b: any) => months.indexOf(b) - months.indexOf(a)
      );
      sortedSectionedDateItems.map((el: string) => {
        const listItems = `${obj[el]} ${el}`;
        availableKeys.push(listItems);
      });
      return (
        <>
          {renderTitle(itemName, c)}
          {availableKeys.map((elm: any, ind: number) => (
            <React.Fragment key={ind}>
              <Typography
                key={sectionedDateItems[elm].candidateID}
                color="textSecondary"
                gutterBottom
              >
                {elm} [{sectionedDateItems[elm]?.length}]
              </Typography>
              {/* {sectionedDateItems[elm].map((e: any) =>
                            <div key={e.candidateID}>
                                {moment(e.dateItem.toDate()).format("DD MMM YYYY")}
                            </div>)} */}
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return renderTitle(itemName, 0);
    }
  };

  const candidatesOpened = () => {
    if (!profileAnalyticsData.length) {
      return <>{renderTitle("candidatesOpened", 0)}</>;
    }

    return (
      <>
        {renderTitle("candidatesOpened", profileAnalyticsData.length)}
        {profileAnalyticsData.map((data: any, index: number) => {
          const date = data?.viewed_at;
          const url = process.env.REACT_APP_MARKETPLACE_BASE_URL || "";
          return (
            <React.Fragment key={index.toString()}>
              <Box display="flex">
                <Typography key={index.toString()} color="textSecondary">
                  {moment(date.toDate()).format("DD MMM YYYY")}
                </Typography>
                <a
                  style={{
                    textDecoration: "none",
                    marginLeft: "8px",
                    color: "rgba(54, 55, 64, 0.71)",
                  }}
                  target="_blank"
                  href={`${url}/search-candidates/${data?.candidateId}`}
                >
                  {data?.candidateId}
                </a>
              </Box>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const getTotalCandiates = (arr: any) =>
    sectionedDates(arr, "totalCandidates");
  const getTotalRequestedIntros = (arr: any) =>
    sectionedDates(arr, "requestedIntros");
  const getTotalDirectContats = (arr: any) =>
    sectionedDates(arr, "directContacts");
  //console.log(recruiter)
  return id ? (
    <Card className={classes.recruiterProfileCard}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {`${recruiter.firstName} ${recruiter?.lastName}`}
          &nbsp;
          {/* <CreatedCandidatesCount
                      fromExtensionCount={recruiter?.candidate_from_extension_count || 0}
                      fromWebsiteCount={recruiter?.candidate_from_website_count || 0}
                    /> */}
        </Typography>
        {recruiter?.company_name && (
          <Box display="flex" alignItems="center">
            <Typography
              style={{ marginRight: 10 }}
              className={classes.contactTexts}
              variant="body2"
              component="p"
            >
              Company Name:
            </Typography>
            <Typography color="textSecondary">
              {recruiter.company_name}
            </Typography>
          </Box>
        )}
        {recruiter?.recruiter_type && (
          <Box display="flex" alignItems="center">
            <Typography
              style={{ marginRight: 10 }}
              className={classes.contactTexts}
              variant="body2"
              component="p"
            >
              Recruiter Type:
            </Typography>
            <Typography color="textSecondary" autoCapitalize="true">
              {capitalize(recruiter.recruiter_type)} {recruiter.recruiter_type.toLowerCase() === "talent" ? "Partner" : ""} {" "}
              <Link
                component="button"
                color="primary"
                underline="always"
                style={{ color: "#0645AD" }}
                variant="button"
                onClick={handleRecruiterTypeReset}
              >
                Reset
              </Link>
            </Typography>
          </Box>
        )}
        <Typography variant="body2" component="p">
          {recruiter?.bio}
        </Typography>
        <br />
        {getTotalCandiates(ownCandidates)}
        {getTotalRequestedIntros(state.requestedIntros)}
        {getTotalDirectContats(state.directContacts)}
        {candidatesOpened()}
        <Box display="flex" style={{ marginTop: 20 }} alignItems="center">
          <Typography
            style={{ marginRight: 10 }}
            className={classes.contactTexts}
            variant="body2"
            component="p"
          >
            RecruiterID:
          </Typography>
          <Typography color="textSecondary">
            {id || "did not get string"}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            style={{ marginRight: 10 }}
            className={classes.contactTexts}
            variant="body2"
            component="p"
          >
            Created at:
          </Typography>
          <Typography color="textSecondary">
            {moment(recruiter?.created_at?.toDate()).format(
              "DD MMM YYYY, h:mm:ss a"
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  ) : null;
};

export default React.memo(RecruiterProfile);
