import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    regular: {
        height: theme.spacing(10)
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: "hidden"
    },
    content: {
        flexGrow: 1,
        padding: "16px 16px 0",
        marginTop: theme.spacing(7),
        marginBottom: "1em",
        height: "90vh",
        overflowX: "hidden",
        overflowY: "scroll",
    },
    button: {
        width: "100%",
    },
    headerButton: {
        padding: "8px 16px",
        borderRadius: "30px",
        color: "#ffffff"
    },
    searchFormWrapper: {
        height: "calc(100vh - 310px)",
        overflow: "scroll",
        overflowX: "hidden",
        [theme.breakpoints.down("xs")]: {
            height: "calc(100vh - 375px)"
        },
        "&::-webkit-scrollbar": {
            width: "3px"
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.secondary.main
        }
    }
}));

export default useStyles;
