import { ActionType, IAction, IRecruiterMap } from "./Interface";
import { IRecruiter } from "compass-shared";

const initialState = {
    user: {} as IRecruiter,
    recruiters: {} as IRecruiterMap,
    clients: {},
    theme: "default"
};

const appReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case ActionType.SET_USER:
            return { ...state, user: action.payload };

        case ActionType.SET_RECRUITERS:
            return { ...state, recruiters: action.payload };

        case ActionType.SET_CLIENTS:
            return { ...state, clients: action.payload };

        case ActionType.APPROVE_RECRUITER:
            return {
                ...state,
                recruiters: {
                    ...state.recruiters,
                    [action.payload.uid]: {
                        ...state.recruiters[action.payload.uid],
                        is_active: action.payload.is_active
                    }
                }
            };

        case ActionType.RESET_RECRUITER_TYPE:
            return {
                ...state,
                recruiters: {
                    ...state.recruiters,
                    [action.payload.uid]: {
                        ...state.recruiters[action.payload.uid],
                        recruiter_type: null,
                        on_boarding_stage: "/onboarding/choose-path"
                    }
                }
            };    
        case ActionType.APPROVE_CLIENT:
            return {
                ...state,
                clients: {
                    ...state.clients,
                    [action.payload.uid]: {
                        ...state.clients[action.payload.uid],
                        is_active: action.payload.is_active
                    }
                }
            };

        default:
            return state;
    }
};

export { appReducer, initialState };

