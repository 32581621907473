import React, { FC, useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

interface IProp {
    cookie?: string;
    onChange: any;
}

const LinkedInCookie: FC<IProp> = ({ cookie, onChange }: IProp) => {
    const [value, setValue] = useState("");
    useEffect(() => {
        if (cookie) {
            setValue(cookie);
        }
    }, [cookie]);

    return (
        <FormControl variant="outlined" style={{ minWidth: "300px", width: "60%" }}>
            <TextField
              fullWidth
              label="LinkedIn cookie"
              variant="outlined"
              id="component-outlined"
              value={value}
              onChange={(e) => {
                  const val = e.target.value;
                  setValue(val);
                  onChange(val);
              }}
            />
        </FormControl>
    );
};

export default LinkedInCookie;
