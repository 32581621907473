import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => createStyles({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: "12px"
    }
}));

export default useStyles;
