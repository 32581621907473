import React, { FC } from "react";
import SignOutIcon from "../../../Assets/img/signout.svg";
import logo from "../../../Assets/img/compassLogo-white.png";
import useStyles from "./style";
import { signOut } from "../../AuthenticationProvider/AuthenticationProvider";
import {
    Button, List, ListItem, ListItemAvatar, ListItemText, Divider, Box, Typography
} from "@material-ui/core";
import { UserAvatar } from "compass-shared";
import useAppContext from "../../../context/store/store";
import { IProps } from "./Interface";
import { Link } from "@reach/router";
import {
    ROUTE_AUTHENTICATED_MANAGE_CLIENTS,
    ROUTE_AUTHENTICATED_MANAGE_RECRUITER,
    ROUTE_AUTHENTICATED_ROOT,
    ROUTE_AUTHENTICATED_SETTING
} from "../../../routes";

const Sidebar: FC<IProps> = (props) => {
    const [{ user }]: any = useAppContext();
    const { children } = props;
    const classes = useStyles();

    return (
        <div className={classes.sidebarContainer}>
            <div className={classes.sidebar}>
                <div>
                    <div className={classes.sidebarHeader}>
                        <img
                          src={logo}
                          alt="logo"
                          className={classes.logo}
                        />
                    </div>
                    <div className={classes.sidebarContent}>
                        <List component="nav">
                            <ListItem>
                                <ListItemAvatar>
                                    <UserAvatar
                                      src={user.photoURL as string}
                                      styleClass={classes.profileAvatar}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                  classes={{
                                      primary: classes.text,
                                      root: classes.wrap
                                  }}
                                  primary={user.displayName}
                                />
                            </ListItem>
                        </List>

                        <Divider
                          classes={{ light: classes.hr }}
                          light
                          variant="middle"
                        />

                        <Box width="80%" margin="auto">

                            <List component="nav" aria-label="secondary mailbox folders">
                                <Link to={ROUTE_AUTHENTICATED_ROOT} style={{ textDecoration: "none" }}>
                                    <ListItem button className={classes.sidebarList}>
                                        <ListItemText primary="Dashboard" />
                                    </ListItem>
                                </Link>
                                {/* <br /> */}
                                <List component="nav" aria-label="secondary mailbox folders">
                                    <Link to={ROUTE_AUTHENTICATED_MANAGE_RECRUITER} style={{ textDecoration: "none" }}>
                                        <ListItem button className={classes.sidebarList}>
                                            <ListItemText primary="Manage Recruiters" />
                                        </ListItem>
                                    </Link>
                                </List>
                                <List style={{ paddingTop: "0px" }} component="nav" aria-label="secondary mailbox folders">
                                    <Link to={ROUTE_AUTHENTICATED_MANAGE_CLIENTS} style={{ textDecoration: "none" }}>
                                        <ListItem button className={classes.sidebarList}>
                                            <ListItemText primary="Manage Clients" />
                                        </ListItem>
                                    </Link>
                                </List>
                                <Link to={ROUTE_AUTHENTICATED_SETTING} style={{ textDecoration: "none" }}>
                                    <ListItem button className={classes.sidebarList}>
                                        <ListItemText primary="Settings" />
                                    </ListItem>
                                </Link>
                            </List>
                        </Box>

                        <div className={classes.childrenContainer}>
                            {children}
                        </div>
                    </div>
                </div>
                <Button
                  type="button"
                  onClick={signOut}
                  className={classes.sidebarFooter}
                  classes={{ label: classes.signOutButton }}
                >
                    <img
                      src={SignOutIcon}
                      alt="icon"
                      style={{ marginRight: "1em", height: "20px" }}
                    />
                    <span>Sign out</span>
                </Button>
            </div>
        </div>
    );
};

export default Sidebar;
