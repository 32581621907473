import React, {
    FC, useEffect, useState, useCallback
} from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {
    useTheme,
} from "@material-ui/core/styles";
import { navigate, RouteComponentProps } from "@reach/router";
import Sidebar from "./Sidebar";
import AppBar from "./Header";
import useStyles from "./style";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { recruitersRef } from "../../FirebaseUtils/collectionRefs";
import useAppContext from "../../context/store/store";
import { auth } from "../../firebase";

const ResponsiveDrawer: FC<RouteComponentProps> = (props) => {
    const { children, location } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [showAppBar, setShowAppBar] = useState(true);
    const [selectedLink, setSelectedLink] = useState("");
    const [{ user }]: any = useAppContext();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // TODO Handle more routes. Consider using reducer

    // Handle the the display of the header component when you navigate to
    // the settings page. This useEffect should be used to manage the display
    // of the header component when one navigates to different pages
    useEffect(() => {
        setSelectedLink(location ?.pathname as string);
        setShowAppBar(true);
    }, [location ?.pathname]);

    const matches = useMediaQuery("(max-width:600px)");

    const checkUserStatus = useCallback(async() => {
        await recruitersRef.doc(user.uid).onSnapshot(snap => {
            if(snap?.data()?.is_active === 'deactivated') {
                auth.signOut()
                .then(() => {
                    navigate('/');
                })
                .catch(err => console.log('ERR', err));
            }
        })
    }, []);

    useEffect(() => {
        checkUserStatus();
    }, [checkUserStatus]);

    // Handle the display of the header component when the viewport width
    // if at 600px or higher
    useEffect(() => {
        // if (matches && location ?.pathname === ROUTE_AUTHENTICATED_SETTINGS) {
        //     setShowAppBar(true);
        // }

        // if (!matches && location ?.pathname === ROUTE_AUTHENTICATED_SETTINGS) {
        //     setShowAppBar(false);
        // }
    }, [matches, location ?.pathname]);

    return (
        <div className={classes.root}>
            <AppBar
              handleDrawerToggle={handleDrawerToggle}
              showAppBar={showAppBar}
            />
            <nav className={classes.drawer} aria-label="sidebar options">
                <Hidden smUp implementation="css">
                    <Drawer
                      variant="temporary"
                      anchor={theme.direction === "rtl" ? "right" : "left"}
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      classes={{
                          paper: classes.drawerPaper
                      }}
                      ModalProps={{
                          keepMounted: true
                      }}
                    >
                        <Sidebar
                          setSelectedLink={setSelectedLink}
                          selectedLink={selectedLink}
                          handleDrawerToggle={handleDrawerToggle}
                        >
                            {/* sidebar children */}
                        </Sidebar>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                      classes={{
                          paper: classes.drawerPaper
                      }}
                      variant="permanent"
                      open
                    >
                        <Sidebar
                          setSelectedLink={setSelectedLink}
                          selectedLink={selectedLink}
                        >
                            {/* sidebar children */}
                        </Sidebar>
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    );
};

export default ResponsiveDrawer;
