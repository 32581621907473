import { IRecruiter } from "compass-shared";

export interface IRecruiterList {
    recruiters: IRecruiter[];
    handleClick: (id: any, status: boolean, name: string) => void;
}

export interface IRecruiterCardProps {
    src?: string;
    name: string;
    email: string;
    bio: string;
    isActive: boolean;
    id: string;
    handleOpen: any;
    candidateFromWebsiteCount?: number;
    candidateFromExtensionCount?: number;
    slack_id?: string;
}

export interface IRecruiterProfile {
    id?: string;
}

export enum EApproveStatus {
    Approve,
    Reject,
    Default
}

export interface IApprovalData {
    uid: string;
    is_active: boolean;
}
