import React, { FC } from "react";
import { RouteComponentProps, Router } from "@reach/router";

import TestPublic from "./Publics/TestPublic/TestPublic";
import {
    ROUTE_AUTHENTICATED_ID,
    ROUTE_AUTHENTICATED_MANAGE_CLIENTS,
    ROUTE_AUTHENTICATED_MANAGE_RECRUITER,
    ROUTE_AUTHENTICATED_ROOT,
    ROUTE_AUTHENTICATED_SETTING,
    ROUTE_PUBLiC_TEST,
    ROUTE_PUBLiC_TEST_CLASS_COMPONENT,
    ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD,
    ROUTE_UNAUTHENTICATED_LOGIN
} from "../routes";
import TestClassComponent from "./Publics/TestPublic/TestClassComponent";
import SignIn from "./UnAuthenticated/SignIn/SignIn";
import ResetPassword from "./UnAuthenticated/ResetPassword/ResetPassword";
import AuthenticationProvider from "../Components/AuthenticationProvider/AuthenticationProvider";
import Dashboard from "./Authenticated/Dashboard";
import Setting from "./Authenticated/Setting";
import NotFound from "./Authenticated/NotFound/NotFound";
import Drawer from "../Components/Drawer";
import Recruiters from "./Authenticated/Recruiters";
import RecruiterProfile from "../Components/Recruiters/RecruiterProfile";
import ClientProfile from "../Components/Clients/ClientProfile";
import Clients from "./Authenticated/Clients";

export const PublicRoutes: FC<RouteComponentProps> = () => (
    <Router>
        <TestPublic path={ROUTE_PUBLiC_TEST} />
        <TestClassComponent path={ROUTE_PUBLiC_TEST_CLASS_COMPONENT} />
    </Router>
);

const UnauthenticatedRouter = () => (
    <Router>
        <SignIn path={ROUTE_UNAUTHENTICATED_LOGIN} default />
        <ResetPassword path={ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD} />
    </Router>
);

export const PrivateRoutes: FC<RouteComponentProps> = () => (
    <AuthenticationProvider
      renderWhenUnauthenticated={UnauthenticatedRouter}
    >
        <Router>
            <Drawer path="/">
                <Dashboard path={ROUTE_AUTHENTICATED_ROOT} />
                <Recruiters path={ROUTE_AUTHENTICATED_MANAGE_RECRUITER}>
                    <RecruiterProfile path={ROUTE_AUTHENTICATED_ID} />
                </Recruiters>
                <Clients path={ROUTE_AUTHENTICATED_MANAGE_CLIENTS}>
                    <ClientProfile path={ROUTE_AUTHENTICATED_ID} />
                </Clients>
                <Setting path={ROUTE_AUTHENTICATED_SETTING} />
                <NotFound default />
            </Drawer>
        </Router>
    </AuthenticationProvider>
);
