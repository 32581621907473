import React, { FC } from "react";
import RecruiterCard from "./RecruiterCard";
import { Grid } from "@material-ui/core";
import ReactList from "react-list";
import { IRecruiter } from "compass-shared";
import { RouteComponentProps } from "@reach/router";
import { IRecruiterList } from "./interface";
import useStyles from "./style";

const RecruiterList: FC<RouteComponentProps & IRecruiterList> = ({
  recruiters,
  handleClick,
}) => {
  const classes = useStyles();  
  const renderList = (index: any, key: any) => {
    const name = `${recruiters[index].firstName} ${recruiters[index].lastName}`;
    const is_active =
      recruiters[index].is_active &&
      (recruiters[index].is_active as any) !== "deactivated"
        ? true
        : false;

    return (
      <RecruiterCard
        bio={recruiters[index].bio || ""}
        src={recruiters[index].thumbnail_avatar_url}
        name={name}
        email={recruiters[index].email}
        isActive={is_active}
        key={recruiters[index].id}
        id={recruiters[index].id || ""}
        slack_id={recruiters[index].slack_id}
        candidateFromWebsiteCount={
          recruiters[index].candidate_from_website_count
        }
        candidateFromExtensionCount={
          recruiters[index].candidate_from_extension_count
        }
        handleOpen={() =>
          handleClick(
            recruiters[index].id,
            (recruiters[index] as any).is_active,
            name
          )
        }
      />
    );
  };
  return (
    <Grid
      container
      spacing={2}
      style={{ width: "100%", paddingBlockEnd: "1em" }}
      className={classes.recruitersContainer}
    >
      <ReactList
        itemRenderer={renderList}
        length={recruiters.length}
        type="variable"
      />
    </Grid>
  );
};

export default RecruiterList;
