import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Grid, Hidden, Theme, useMediaQuery } from "@material-ui/core";
import { toast } from "react-toastify";

import { functions } from "../../../firebase";
import ClientsList from "../../../Components/Clients/ClientsList";
import { clientsRef } from "../../../FirebaseUtils/collectionRefs";
import { clientsCompanyRef } from "../../../FirebaseUtils/collectionRefs";
import { IRecruiter, FailToLoad, EmptyList, ErrorMsg } from "compass-shared";
import {
  doApproveClient,
  doSetClients,
} from "../../../context/reducer/Actions";
import useAppContext from "../../../context/store/store";
import { LoadingPage } from "compass-shared";
import useStyles from "../../../Components/Recruiters/style";
import { AlertDialog } from "compass-shared";
import { IRecruiterMap } from "../../../context/reducer/Interface";
import {
  EApproveStatus,
  IApprovalData,
} from "../../../Components/Recruiters/interface";

const Clients: FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const [store, dispatch]: any = useAppContext();
  const [loading, setLoading] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [error, setError] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [currentRecruiterId, setCurrentRecruiterId] = React.useState("");
  const [currentRecruiterName, setCurrentRecruiterName] = React.useState("");
  const [currentRecruiterStatus, setCurrentRecruiterStatus] =
    React.useState<EApproveStatus>(EApproveStatus.Default);

  const handleClose = () => {
    setOpen(false);
    setCurrentRecruiterId("");
    setCurrentRecruiterName("");
    setCurrentRecruiterStatus(EApproveStatus.Default);
  };

  const approveProfile = async (
    uid: string,
    approvalStatus: EApproveStatus
  ) => {
    setDialogLoading(true);
    const is_active = approvalStatus === EApproveStatus.Approve;
    const data: IApprovalData = { uid, is_active };
    try {
      const updateRecruiterPermission: any = functions.httpsCallable(
        "admins-setClientPermission"
      );
      const { data: updateRecruiterPermissionResponse } =
        await updateRecruiterPermission(data);
      if (!updateRecruiterPermissionResponse.result) {
        setLoading(false);
        toast.error(
          <ErrorMsg text={updateRecruiterPermissionResponse?.message || ""} />
        );
        // eslint-disable-next-line consistent-return
        return;
      }
      dispatch(doApproveClient(data));
    } catch (e) {
      toast.error(<ErrorMsg text="Something went wrong please try again" />);
    } finally {
      setOpen(false);
      setDialogLoading(false);
    }
  };

  const handleClick = (id: string, status: boolean | string, name: string) => {
    setOpen(true);
    setCurrentRecruiterId(id);
    setCurrentRecruiterName(name);
    const is_active = status && status !== "deactivated" ? true : false;
    if (is_active) {
      setCurrentRecruiterStatus(EApproveStatus.Reject);
    } else {
      setCurrentRecruiterStatus(EApproveStatus.Approve);
    }
  };

  const addCompanyNameToClient = (clientData: object) => {
    dispatch(doSetClients(clientData));
    Object.keys(clientData).forEach((clientID: string) => {
      const query = clientsCompanyRef.where("attached_clients", "array-contains", clientID);
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const companyData = doc.data();
          if(companyData.attached_clients.length){
            companyData.attached_clients.map((el: any) => {
              if(clientData[el]) {
                clientData[el].company_name = companyData.name;
              }
            })
          }
        });
        return clientData;
      }).then((processedData)=>{
        dispatch(doSetClients(processedData));
      });
    });
    return clientData;
  };

  useEffect(() => {
    const _data: IRecruiterMap = {};
    setLoading(true);

    const query = clientsRef.orderBy("created_at", "desc");
    query
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          setEmpty(true);
          setLoading(false);
          return;
        }
        snapshot.forEach((doc) => {
          return (_data[doc.id] = { id: doc.id, ...doc.data() } as IRecruiter);
        });
        addCompanyNameToClient(_data);        
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, []);

  const matches = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("xs", 960)
  );
  // eslint-disable-next-line react/destructuring-assignment
  const idNotInPath = props["*"] === "";

  const renderList = () => (
    <Grid
      item
      xs={12}
      sm={12}
      md={7}
      className={`${classes.wrapper} ${classes.childWrapper}`}
    >
      <ClientsList
        handleClick={handleClick}
        clients={Object.values(store.clients)}
      />
    </Grid>
  );

  return error ? (
    <FailToLoad text={error} />
  ) : loading ? (
    <LoadingPage text="Recruiter List" />
  ) : empty ? (
    <EmptyList text="No Recruiters" />
  ) : (
    <>
      <Grid container wrap="nowrap" justify="space-between">
        {idNotInPath
          ? renderList()
          : !idNotInPath && matches
          ? null
          : renderList()}
        <Hidden only={idNotInPath ? ["sm", "xs"] : undefined}>
          <Grid item xs={12} md={5} className={classes.childWrapper}>
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {props.children}
          </Grid>
        </Hidden>
      </Grid>
      <AlertDialog
        open={open}
        loading={dialogLoading}
        handleClose={handleClose}
        handleAccept={() =>
          approveProfile(currentRecruiterId, currentRecruiterStatus)
        }
        title="Are you sure?"
        text={
          <span>
            You are about to{" "}
            <span
              style={{
                fontWeight: "bold",
                color: currentRecruiterStatus ? "red" : "green",
              }}
            >
              {currentRecruiterStatus ? "reject" : "approve"}
            </span>{" "}
            the recruiter{" "}
            <span
              style={{
                fontWeight: "bold",
                color: currentRecruiterStatus ? "red" : "green",
              }}
            >
              {currentRecruiterName}
            </span>
          </span>
        }
      />
    </>
  );
};

export default Clients;
