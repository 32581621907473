import React, { useEffect, useState, useCallback } from "react";
import useStyles from "./style";
// import Graph from "./Graph";
import { Grid } from "@material-ui/core";
import Salutation from "./Salutation";
import MetricCard from "./MetricCard";
import { candidatesRef, recruitersRef, tallyUserRef, subscribedQueriesRef } from "../../FirebaseUtils/collectionRefs";
import useAppContext from "../../context/store/store";

const initialState = {
    'total-candidates': {total : 0},
    activeJobOpening: 0,
    allJobOpening: 0,
    jobFunctionList: { headers: [], data: [] },
    isExportinSkills: '',
}

const Dashboard = () => {
    const [store]: any = useAppContext();
    const username = store.user.displayName;
    const [loading, setLoading] = useState(false);
    const [candidateCount, setCandidateCount] = useState(0);
    const [state, setState] = useState(initialState);
    const [recruiterCount, setRecruiterCount] = useState(0);
    const [verifiedCandidateCount, setVerifiedCandidateCount] = useState(0);
    const [approvedRecruiterCount, setApprovedRecruiterCount] = useState(0);

    async function getDashboardMetrics() {
        setLoading(true);

        const [candidateQuerySnapshot, recruiterQuerySnapshot, tallyUserSnapshot] = await Promise.all([
            candidatesRef.get(),
            recruitersRef.get(),
            tallyUserRef.get()
        ]);

        const _verifiedCandidates = await candidateQuerySnapshot.query.where("is_verified", "==", true).get();
        const _approvedRecruiters = await recruiterQuerySnapshot.query.where("is_active", "==", true).get();
        tallyUserSnapshot.forEach(doc => {
            setState(st => ({
                ...st,
                [`total-${doc.id}`]: doc.data()
            }))
        })

        setCandidateCount(candidateQuerySnapshot.size);
        setRecruiterCount(recruiterQuerySnapshot.size);
        setVerifiedCandidateCount(_verifiedCandidates.size);
        setApprovedRecruiterCount(_approvedRecruiters.size);

        setLoading(false);
    }
    const getJobOppenings = useCallback(async () => {
        const [jobOpenningSnap, activeJobOpenningSnap] = await Promise.all([
            subscribedQueriesRef.get(),
            subscribedQueriesRef.where('is_active','==', "Open").get()
        ]);

        setState(st => ({
            ...st,
            allJobOpening: jobOpenningSnap.size,
            activeJobOpening: activeJobOpenningSnap.size
        }))
    }, []);

    useEffect(() => {
        getJobOppenings();
    }, [getJobOppenings])

    useEffect(() => {
        // TODO: find a better solution
        getDashboardMetrics().catch((err) => console.log(err));
    }, []);
    const classes = useStyles();


    return (
        <Grid container className={classes.dashboard}>
            <Grid item className={classes.salutation}>
                <Salutation name={username.split(" ")[0]} />
            </Grid>
            <Grid style={{ display: 'flex' }} item xs={12}>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <MetricCard
                            metric={candidateCount}
                            title={(
                                <div>
                                    <p>Candidates</p>
                                    <p>Active Now</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                        <Grid item>
                            <MetricCard
                            //   metric={verifiedCandidateCount}
                            metric={state?.["total-candidates"]?.total}
                            title={(
                                <div>
                                    <p>Candidates</p>
                                    <p>All-Time</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <MetricCard
                            metric={approvedRecruiterCount}
                            title={(
                                <div>
                                    <p>Recruiters</p>
                                    <p>Active Now</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                        <Grid item>
                            <MetricCard
                            metric={recruiterCount}
                            title={(
                                <div>
                                    <p>Recruiters</p>
                                    <p>All-Time</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                        <Grid onClick={exportSkills} item style={{ padding: 0, margin: 12, cursor: 'pointer' }}>
                            <MetricCard
                                title={(
                                    <div>
                                        <p>
                                            {state.isExportinSkills === 'pending' ? 'Exporting ...' :
                                                state.isExportinSkills === 'fulfilled' ? 'Export again' : 'Export skills'
                                            }
                                        </p>
                                    </div>
                                )}
                                loading={state.isExportinSkills === 'pending'}
                            />
                        </Grid>
                        <Grid item style={{ padding: 0, margin: 12, cursor: 'pointer' }}>
                            {state.isExportinSkills === 'fulfilled' ?
                                <div style={{ height: '100%', alignItems: 'flex-end', display: 'flex'}}>
                                    <CSVLink data={state.jobFunctionList.data} headers={state.jobFunctionList.headers}>Download csv</CSVLink>
                                </div>: null
                            }
                        </Grid> */}
                    {/* </Grid> */}
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <MetricCard
                            metric={state?.["total-recruiters"]?.requested_intro}
                            title={(
                                <div>
                                    <p>Intros Requested</p>
                                    <p>All-Time</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                        <Grid item>
                            <MetricCard
                            //   metric={verifiedCandidateCount}
                            metric={state?.["total-recruiters"]?.direct_contact}
                            title={(
                                <div>
                                    <p>Direct Contacts</p>
                                    <p>All-Time</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item>
                            <MetricCard
                            metric={state?.activeJobOpening}
                            title={(
                                <div>
                                    <p>Job Openings</p>
                                    <p>Active Now</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                        <Grid item>
                            <MetricCard
                            metric={state?.allJobOpening}
                            title={(
                                <div>
                                    <p>Job Openings</p>
                                    <p>All-Time</p>
                                </div>
                            )}
                            loading={loading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* {false && ( */}
            {/*    <Grid item sm={12} className={classes.graph}> */}
            {/*        <Graph /> */}
            {/*    </Grid> */}
            {/* )} */}

        </Grid>
    );
};

export default Dashboard;
