// Un authenticated pages
export const ROUTE_UNAUTHENTICATED_LOGIN = "/";
export const ROUTE_UNAUTHENTICATED_FORGOT_PASSWORD = "/forgot-password";

// Authenticated pages
export const ROUTE_AUTHENTICATED_ROOT = "/";
export const ROUTE_AUTHENTICATED_SETTING = "/settings";
export const ROUTE_AUTHENTICATED_MANAGE_RECRUITER = "/manage-recruiters";
export const ROUTE_AUTHENTICATED_MANAGE_CLIENTS = "/manage-clients";
export const ROUTE_AUTHENTICATED_ID = ":id";

// Public pages
export const ROUTE_PUBLiC_TEST = "test";
export const ROUTE_PUBLiC_TEST_CLASS_COMPONENT = "test-class-component";
