import React, { FC } from "react";
import ClientCard from "./ClientCard";
import { Grid } from "@material-ui/core";
import ReactList from "react-list";
import { IRecruiter } from "compass-shared";
import { RouteComponentProps } from "@reach/router";
import { IRecruiterList } from "./interface";
import useStyles from "./style";

const ClientsList: FC<RouteComponentProps & IRecruiterList> = ({
  clients,
  handleClick,
}) => {
  const classes = useStyles();
  const renderList = (index: any, key: any) => {
    const name = `${clients[index].firstName} ${clients[index].lastName}`;
    const is_active =
      clients[index].is_active &&
      (clients[index].is_active as any) !== "deactivated"
        ? true
        : false;

    return (
      <ClientCard
        bio={clients[index].bio || ""}
        src={clients[index].thumbnail_avatar_url}
        name={name}
        company_name={clients[index].company_name}
        email={clients[index].email}
        isActive={is_active}
        key={clients[index].id}
        id={clients[index].id || ""}
        candidateFromWebsiteCount={clients[index].candidate_from_website_count}
        candidateFromExtensionCount={
          clients[index].candidate_from_extension_count
        }
        handleOpen={() =>
          handleClick(clients[index].id, clients[index].is_active, name)
        }
      />
    );
  };
  return (
    <Grid
      container
      spacing={2}
      style={{ width: "100%", paddingBlockEnd: "1em" }}
      className={classes.clientsContainer}
    >
      <ReactList
        itemRenderer={renderList}
        length={clients.length}
        type="variable"
      />
    </Grid>
  );
};

export default ClientsList;
