import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { useForm } from "react-hook-form";
import {
    Container,
    CssBaseline,
    Grid,
    Typography
} from "@material-ui/core";
import {
    sendPasswordResetEmail
} from "../../../Components/AuthenticationProvider/AuthenticationProvider";
import { SubmitButton } from "compass-shared";
import { EmailField, ErrorMsg, SuccessMsg } from "compass-shared";
import { ForgotPasswordSchema } from "../../../Utils/FormValidationSchema";
import useStyles from "./styles";
import {
    ROUTE_UNAUTHENTICATED_LOGIN
} from "../../../routes";
import CompassLogo from "../../../Assets/img/compassLogo.png";

const ResetPassword: FC<RouteComponentProps> = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: ForgotPasswordSchema
    });
    const classes = useStyles();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            await sendPasswordResetEmail(data.email);
            setIsLoading(false);
            toast.success(
                <SuccessMsg text="Reset password link sent successfully" />
            );
            navigate(ROUTE_UNAUTHENTICATED_LOGIN);
        } catch (error) {
            console.log("error in signin :: ", error);
            if (error.code === "auth/user-not-found") {
                toast.error(<ErrorMsg text="User not found" />);
            } else {
                toast.error(<ErrorMsg text={error.message} />);
            }
            setIsLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img
                  src={CompassLogo}
                  alt="compass logo"
                  className={classes.compassLogo}
                />
                <br />
                <br />
                <Typography component="h1" variant="h5">
                    Forgot password?
                </Typography>
                <br />
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={classes.form}
                >

                    <EmailField register={register} errors={errors} autoFocus />

                    <SubmitButton
                      className={classes.submit}
                      text="Reset password"
                      loading={isLoading}
                    />
                    <Grid container>
                        <Grid item>
                            <Link to={ROUTE_UNAUTHENTICATED_LOGIN}>
                                Back to sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

export default ResetPassword;
