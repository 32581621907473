import React, { FC, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ISalutation } from "./interface";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import morning from "../../Assets/img/morning.svg";
import afternoon from "../../Assets/img/afternoon.svg";
import night from "../../Assets/img/night.svg";

const Salutation: FC<ISalutation> = ({ name }) => {
    const [timeImage, setTimeImage] = useState("");
    const [greeting, setGreeting] = useState("");
    useEffect(() => {
        const _time = new Date().getHours();

        if (_time >= 6 && _time < 12) {
            setTimeImage(morning);
            setGreeting("Morning");
        } else if (_time >= 12 && _time < 16) {
            setTimeImage(afternoon);
            setGreeting("Afternoon");
        } else {
            setTimeImage(night);
            setGreeting("Evening");
        }
    }, []);

    const matches = useMediaQuery("(max-width:820px)");

    return (
        <Grid container>
            <Grid item xs={8} sm={8} lg={10}>
                <Typography
                  variant="h3"
                  paragraph
                >
                    {`Good ${greeting?.toLowerCase()}, ${name}!`}
                </Typography>
                <Typography variant="h6" color="secondary" paragraph>
            Here's what's happening with Connect today
                </Typography>
            </Grid>
            {!matches && (
            <Grid item lg={2}>
                <img src={timeImage} alt="sun" style={{ width: "10em" }} />
            </Grid>
            )}
        </Grid>
    );
};

export default Salutation;
