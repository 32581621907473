import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paperWrapper: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            width: "100%",
            padding: theme.spacing(2),
            margin: theme.spacing(1),
        },
    },
}));

export default useStyles;
